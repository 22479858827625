html {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393939;
  cursor: default;
  height: 100%;
  background-image: url(../assets/images/background.jpg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}

code {
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #575757;
  border-radius: 10px;
}

@media (max-width: 767px) {
  body { 
    background-image: none;
    background-position: initial;
    background-repeat: initial;
    background-size: initial;
    position: initial;
  }
 
}