.alert-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.alert-popup {
  width: 450px;
  border-radius: 0;
}
