.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 75vw;
  margin: auto;
  gap: 10vh;
  background-color: #ffffff ;
  height: auto;
  min-height: 100vh;
}

@media (max-width: 991px) {
  .App {
    gap: 3vh;
  }
}
